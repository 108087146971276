@charset "UTF-8";
// Make sure the charset is set appropriately

@import "compass";
@import "theme_variables";

/* promo boxes */
.promo-boxes{
  margin: 22px 0 53px;
  .article{
    text-align: center;
    color: #484848;
    font-weight: 300;
    line-height: 18px;
    @include md{
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
  figure{
    min-height: 62px;
    position: relative;
    img{
      position: absolute;
      @include hv_center;
      z-index: 1;
    }
  }
  figcaption{
    position: relative;
    width: 100%;
    z-index: 2;
    font-size: 13px;
    text-transform: uppercase;
  }
  .content{
    font-size: 11px;
    &:after{
      display: block;
      margin: 8px auto;
      content: '';
      width: 64px;
      @include box-shadow(0 2px 2px rgba(51, 51, 51, 0.2));
      border-bottom: 1px solid #e86342;
      opacity: 0.5;
    }
  }
}

/* END OF  promo boxes */

/* banners on homepage */
.home-banners{
  /* grid modifications for banners */
  > .row{
    margin-left: -4.5px;
    margin-right: -4.5px;
  }
  .col-md-4{
    padding-left: 4.5px;
    padding-right: 4.5px;
  }

  /* banners specific styles */
  .delim{
    display: block;
    margin: 10px auto;
    width: 66px;
    border-bottom: 1px solid #e86342;
  }
  .banner{
    text-align: center;
    margin-bottom: 11px;
    h3{
      margin: 16px 0;
      text-shadow: 0 3px 3px rgba(51, 51, 51, 0.5);
      color: #f2f2f2;
      font-size: 30px;
      font-weight: 300;
      line-height: 28px;
      text-transform: uppercase;
    }
  }
  .content{
    color: #333;
    font-size: 11px;
    font-weight: 300;
    line-height: 16px;
    margin: 20px 0;
  }
  .content-wrapper{
    padding: 10px;
    min-height: 327px;
    @include box-shadow(0 6px 6px rgba(51, 51, 51, 0.35));
    &--image-only{
      min-height: unset;
      padding: 0;
      .img{
        width: 100%;
        height: auto;
        display: block;
        margin: 0;
        padding: 0;
      }
      > a{
        display: block;
        width: 100%;
      }
    }
  }
  .textual-link{
    margin: 20px 0;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    color: #e86342;
    &:focus{
      text-decoration: none;
    }
    .crux-right:after{
      font-size: 9px;
      vertical-align: middle;
    }
  }
  .inner{
    width: 100%;
  }
  .button.button-default{
    width: 131px;
    text-align: center;
  }

  /* variants */
  .banner.right,
  .banner.right-image-only{
    position: relative;
    padding: 0 0 18px 12px;
    .content-wrapper{
      z-index: 2;
      position: relative;
    }
    .frame{
      position: absolute;
      z-index: 1;
      top: 18px;
      right: 18px;
      bottom: 0;
      left: 0;
      border: 1px solid #405997;
      @include box-shadow(inset 0px 0px 5px -1px rgba(64,89,151,1));
    }
  }
  .banner.left,
  .banner.left-image-only{
    position: relative;
    padding: 0 12px 18px 0;
    .content-wrapper{
      z-index: 2;
      position: relative;
    }
    .frame{
      position: absolute;
      z-index: 1;
      top: 18px;
      left: 18px;
      bottom: 0;
      right: 0;
      border: 1px solid #405997;
      @include box-shadow(inset 0px 0px 5px -1px rgba(64,89,151,1));
    }
  }
  .banner.textual{
    h3{
      color: #484848;
      text-shadow: none;
    }
    .content-wrapper{
      border: 1px solid #eeeeee;
      background-color: #ffffff;
    }
  }
}
/* END OF banners on homepage */

.home-contact-link{
  text-align: center;
  margin: 69px 0 34px;
  .h3{
    margin: 37px 0;
    color: #484848;
    font-size: 33px;
    font-weight: 300;
    line-height: 40px;
    text-transform: uppercase;
  }
  .button-medium{
    min-width: 170px;
  }
}

/* form elements */

div.selector{
  background: #fff;
  line-height: 35px;
  height: 35px;
  padding: 0 0 0 10px;
  border: 1px solid #b7b7b7;
  @include border-radius(0);
  span{
    background: #fff !important;
    line-height: 35px;
    height: 35px;
    &:after{
      position: absolute;
      right: 16px;
      top: 10px;
      @include arrow_border(down, 1px, 8px, 8px );
    }
  }
  &.active{
    span:after{
      top: 14px;
      @include arrow_border(up, 1px, 8px, 8px );
    }
  }
  select{
    @include absolute-stretch;
  }
}
/* selector full width */
.selector-fw{
  div.selector{
    width: 302px !important;
    max-width: 100%;
    span{
      width: 100% !important;
    }
  }
}
.checkbox{
  padding-left: 24px;
  position: relative;
  div.checker{
    position: absolute;
    left: 0;
    top: 0;
  }
  label{
    font-size: 11px;
    font-weight: 300;
    line-height: 15px;
    color: #666;
    a{
      text-decoration: underline;
    }
  }
}
ul.pagination{
  margin-right: -7px;
}
.pagination {
  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      border: none;
      position: relative;
      float: left; // Collapse white-space
      padding: 0 7px;
      line-height: 32px;
      font-size: 15px;
      font-weight: 300;
      text-decoration: none;
      background-color: transparent;
      margin-left: -1px;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
        @include border-left-radius($border-radius-base);
      }
    }
    &:last-child {
      > a,
      > span {
        @include border-right-radius($border-radius-base);
      }
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      background-color: transparent;
      color: #111;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: #111;
      text-decoration: underline;
      background-color: transparent;
      border-color: transparent;
      cursor: default;
    }
  }

  > .disabled {
    > span,
    > a,
    > a:hover,
    > a:focus {
      color: $pagination-disabled-color;
      background-color: transparent;
      border-color: transparent;
      cursor: not-allowed;
    }
  }
}



.ec-cart-navigation, .cart_navigation{
  .button-exclusive,
  .button-medium {
    margin-right: 0;
    padding: 14px 18px;
    line-height: 20px;
    background-color: $crux-carrot-color;
    border: 1px solid $crux-carrot-color;
    color: #ffffff;
    text-transform: uppercase;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 300;
    width:  371px;
    max-width: 100%;
    text-align: center;
    @include border-radius(0);
    @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
  }
  .button-exclusive{
    font-size: 11px;
    background-color: transparent;
    border-color: transparent;
    width:  auto;
    padding: 14px 0;
    color: #b8b8b8;
    text-decoration: underline;
    float: left;
    @include box-shadow(none);
    &:focus, &:hover{
      @include box-shadow(none);
      color: #787878;
    }
  }
}
@import "spin-variables";

.sk-cube-grid {
  $delayRange: 0.4s;

  width: $spinkit-size;
  height: $spinkit-size;
  margin: $spinkit-spinner-margin;

  .sk-cube {
    width: 33.33%;
    height: 33.33%;
    background-color: $spinkit-spinner-color;
    float: left;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }

  /*
   * Spinner positions
   * 1 2 3
   * 4 5 6
   * 7 8 9
   */

  .sk-cube1 { animation-delay: $delayRange * 0.50 }
  .sk-cube2 { animation-delay: $delayRange * 0.75 }
  .sk-cube3 { animation-delay: $delayRange }
  .sk-cube4 { animation-delay: $delayRange * 0.25 }
  .sk-cube5 { animation-delay: $delayRange * 0.50 }
  .sk-cube6 { animation-delay: $delayRange * 0.75 }
  .sk-cube7 { animation-delay: 0.0s }
  .sk-cube8 { animation-delay: $delayRange * 0.25 }
  .sk-cube9 { animation-delay: $delayRange * 0.50 }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% { transform:scale3D(1.0, 1.0, 1.0) }
  35%           { transform:scale3D(0.0, 0.0, 1.0) }
}
